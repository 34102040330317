<template>
  <div class="row justify-content-center">
    <div class="col-11">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            >
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_form_add"
                  @submit.stop.prevent="dataEdit()"
                >
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Edit Peran
                    </h3>
                    <div class="form-group">
                      <label>Nama</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="name"
                        placeholder="Nama Peran"
                        ref="name"
                        v-model="post.name"
                      />
                    </div>

                    <div class="form-group">
                      <label>Deskripsi</label>
                      <b-form-textarea
                        id="textarea"
                        placeholder="Deskripsi Peran"
                        rows="3"
                        max-rows="6"
                        class="form-control form-control-solid"
                        v-model="post.description"
                        ></b-form-textarea
                      >
                      <!-- <span class="form-text text-muted">Masukkan Nama</span> -->
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <router-link
                        to="/masters/roles"
                        class="btn btn-info font-weight-bold text-uppercase px-11 py-4"
                      >
                        batal
                      </router-link>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-11 py-4 ml-2"
                        ref="kt_form_add"
                      >
                        simpan
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import Swal from "sweetalert2";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

// form validation
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";

export default {
  data() {
    return {
      post: {
        name: "",
        description: "",
      },
      routeId: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Peran", route: "/masters/roles" },
      { title: "Edit Peran" },
    ]);

    // get data by id
    this.routeId = this.$route.params.id
    this.getDataById()

    // rules form validation
    const data_add_form = KTUtil.getById("kt_form_add");

    this.fv = formValidation(data_add_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Nama peran harus diisi",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    getDataById() {
      ApiService.get("api/roles/" + this.$route.params.id).then((response) => {
        this.post = response.data.data
      });
    },
    dataEdit() {
      // this.fv.validate();

      this.fv.on("core.form.valid", () => {
        // set spinner to submit button
        const submitButton = this.$refs["kt_form_add"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          ApiService.put("api/roles/" + this.$route.params.id, this.post)
            .then(() => {
              Swal.fire({
                title: "",
                text: "Data berhasil diedit",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                // heightAuto: false,
              });
              this.$router.push({ path: "/masters/roles" });
            })
            .catch((error) => {
              //("responserror", error);
              Swal.fire({
                title: "",
                text: "Data gagal diedit",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                // heightAuto: false,
              });
              this.$router.push({ path: "/masters/roles" });
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Mohon isi data dengan benar!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
  },
};
</script>